/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/mortalone/opencart@3.0.2.0/upload/catalog/view/javascript/bootstrap/css/bootstrap.min.css
 * - /gh/mortalone/opencartjs@master/font-awesome1.min.css
 * - /gh/mortalone/opencart@3.0.2.0/upload/catalog/view/javascript/jquery/swiper/css/swiper.min.css
 * - /gh/mortalone/opencart@3.0.2.0/upload/catalog/view/javascript/jquery/swiper/css/opencart.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
